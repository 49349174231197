import React from "react";
import {
  Grid,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  OutlinedInput,
  CircularProgress,
  TextareaAutosize,
  Box,
  FormHelperText,
  Checkbox,
  Select
} from "@mui/material";

import { statuses } from "./CreateNewJob";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { useGetLists } from "services/lists";
import { Job, editJob } from "services/jobs";
import { useGetMailwizzLists } from "services/lists";
import { daysOfWeek } from "../../constants";
interface Props {
  onSuccess: () => void;
  job: Job;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditJob = ({ onSuccess, job, isEditing, setIsEditing }: Props) => {
  const { data: lists } = useGetLists();
  const { data: mailwizzLists } = useGetMailwizzLists();
  const { isError, isLoading, mutate } = useMutation(
    (jobParam: Partial<Job>) => editJob({ ...jobParam, id: job.id }),
    {
      onError: (error) => {
        console.log(error);
      },
      onSuccess: onSuccess,
    }
  );

  const handleCancel = (setValues: any, initialValues: any) => {
    setIsEditing(false);
    setValues(initialValues);
  };

  return (
    <Formik
      initialValues={{
        list_ids: job.list_ids || [],
        dailyLeadCap: job.daily_lead_cap || 0,
        notes: job.notes || "",
        recurring: job.recurring,
        monday: job.monday || false,
        tuesday: job.tuesday || false,
        wednesday: job.wednesday || false,
        thursday: job.thursday || false,
        friday: job.friday || false,
        saturday: job.saturday || false,
        sunday: job.sunday || false,
        dayLastMailed: job.day_last_mailed || "",
        status: job.status,
        name: job.name || "",
        includeISP: job.include_isp || "",
        excludeISP: job.exclude_isp || "",
        mailwizzList: job.mailwizz_list || "",
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        list_ids: Yup.array().required("List is required").min(1),
        name: Yup.string().required("Name is required"),
        dailyLeadCap: Yup.number().required("Required").lessThan(10000000),
        notes: Yup.string(),
        recurring: Yup.boolean(),
        monday: Yup.boolean(),
        tuesday: Yup.boolean(),
        wednesday: Yup.boolean(),
        thursday: Yup.boolean(),
        friday: Yup.boolean(),
        saturday: Yup.boolean(),
        sunday: Yup.boolean(),
        dayLastMailed: Yup.number(),
        status: Yup.string(),
        includeISP: Yup.string(),
        excludeISP: Yup.string(),
        mailwizzList: Yup.string().required("Mailwizz List is required"),
      })}
      onSubmit={(values, { setErrors, setStatus }) => {
        try {
          if (values.list_ids.length > 0) {
            mutate(values);
          }
        } catch (e) {
          console.log(e);
          if (e instanceof Error) {
            console.log(e.message);
            setStatus({ success: false });
            setErrors({ submit: e.message });
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        setValues,
        initialValues
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {(lists && mailwizzLists) ? (<Grid container direction="column" spacing={2} p={2} pb={0} pt={1}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Name
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(
                  touched.name && errors.name
                )}
              >
                <OutlinedInput
                  id="name"
                  type="text"
                  disabled={!isEditing}
                  name="name"
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                />
              </FormControl>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Select List
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    multiple
                    id="list_ids"
                    name="list_ids"
                    value={values.list_ids}
                    onBlur={handleBlur}
                    disabled={!isEditing}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("list_ids", typeof value === 'string' ? value.split(',') : value);
                    }}
                  >
                    {lists.length > 0
                      ? lists.map((list) => (
                        <MenuItem key={list.id} value={list.id}>
                          {list.list_name}
                        </MenuItem>
                      ))
                      : "No Lists"}
                  </Select>

                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Daily Lead Cap
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.dailyLeadCap && errors.dailyLeadCap
                  )}
                >
                  <OutlinedInput
                    id="dailyLeadCap"
                    disabled={!isEditing}
                    type="number"
                    value={values.dailyLeadCap}
                    name="dailyLeadCap"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container spacing={2} >
              <Grid item xs={12} md={12}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Recurring?
                </InputLabel>
                <FormControl
                  error={Boolean(touched.recurring && errors.recurring)}
                >
                  <Checkbox
                    checked={values.recurring}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!isEditing}
                    name="recurring"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} container spacing={2}>
                {daysOfWeek.map((day) => (
                  <Grid item key={day}>
                    <InputLabel htmlFor="outlined-adornment-email-login">
                      {capitalizeFirstLetter(day)}
                    </InputLabel>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        (touched as any)[day] && (errors as any)[day]
                      )}
                    >
                      <Checkbox
                        disabled={!isEditing}
                        checked={(values as any)[day]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={day}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </FormControl>
                  </Grid>
                ))}

              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Days Last Mailed
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.dayLastMailed && errors.dayLastMailed
                  )}
                >
                  <OutlinedInput
                    id="dayLastMailed"
                    type="number"
                    disabled={!isEditing}
                    name="dayLastMailed"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Status
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    id="status"
                    name="status"
                    value={values.status}
                    onBlur={handleBlur}
                    disabled={!isEditing}
                    onChange={handleChange}
                  >
                    {
                      statuses.map((status) => (
                        <MenuItem key={status} value={status.toUpperCase()}>
                          {status}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Include ISP
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.includeISP && errors.includeISP)}
                >
                  <OutlinedInput
                    id="includeISP"
                    type="text"
                    disabled={!isEditing}
                    value={values.includeISP}
                    name="includeISP"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Exclude ISP
                </InputLabel>
                <FormControl
                  fullWidth
                  error={Boolean(touched.excludeISP && errors.excludeISP)}
                >
                  <OutlinedInput
                    id="excludeISP"
                    disabled={!isEditing}
                    type="text"
                    value={values.excludeISP}
                    name="excludeISP"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>

              <Grid item xs={12} md={12}>
                <InputLabel htmlFor="outlined-adornment-email-login">
                  Mailwizz List
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    id="mailwizzList"
                    name="mailwizzList"
                    disabled={!isEditing}
                    value={values.mailwizzList}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {mailwizzLists.records.map(list => (
                      <MenuItem key={list.general.list_uid} value={list.general.list_uid}>
                        {list.general.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {touched.mailwizzList && errors.mailwizzList && (
                  <FormHelperText error>{errors.mailwizzList}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item>
              <InputLabel htmlFor="outlined-adornment-email-login">
                Notes
              </InputLabel>
              <FormControl
                fullWidth
                error={Boolean(touched.notes && errors.notes)}
              >
                <TextareaAutosize
                  name="notes"
                  minRows={3}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </Grid>
          </Grid>) : 'Loading...'}

          {isError && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {"Failed to Edit Job"}
              </FormHelperText>
            </Box>
          )}
          <Grid item container spacing={2} pt={2}>
            {isEditing ? (
              <>
                <Grid item xs={12} md={6} >
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    onClick={() => handleCancel(setValues, initialValues)}
                    variant="contained"
                    color="warning"
                    disabled={isLoading}
                  >
                    {"Cancel"}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    sx={{ mt: 2 }}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    color="info"
                    variant="contained"
                    disabled={isLoading}
                  >
                    {isLoading ? <CircularProgress /> : "Update"}
                  </Button>
                </Grid>
              </>
            ) : (
              <Button
                sx={{ mt: 3 }}
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                onClick={() => setIsEditing(true)}
                color="primary"
              >
                {"Edit Job"}
              </Button>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default EditJob;
