import React from "react";
import {
  Box,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  tableCellClasses,
  TableSortLabel,
  styled
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Order } from './index';

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.grey[500],
    borderBottom: `1px solid ${theme.palette.grey[700]}1A`,
    height: "35px",
  },
}));

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: any[];
  }
  
  function EnhancedTableHead(props: EnhancedTableProps) {
    const {
      order,
      orderBy,
      onRequestSort,
    } = props;
    const createSortHandler =
      (property: string, numeric?:boolean) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead>
        <TableRow>
          {props.headCells.map((headCell) => (
            <TableCell
              
              key={headCell.id}
              align={headCell.align || "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id,headCell.numeric)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  export default EnhancedTableHead;