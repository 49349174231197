import React from "react";
import { useRoutes } from "react-router-dom";

import DashboardLayout from "components/ui/layouts/DashboardLayout";
import TablesLayout from "components/ui/layouts/TablesLayout";
//Pages
import Dashboard from "pages/dashboard";
import Lists from "pages/Lists";
import List from "pages/List";
import Scrubs from "pages/Scrubs";
import Jobs from "pages/Jobs";
import Profiles from "pages/Profiles";
import Profile from "pages/Profile";
import ScrubVendors from "pages/ScrubVendors";
import ScrubVendor from "pages/ScrubVendor";
import Raw from "pages/Tables/Raw";
import Job from "pages/Job";

import Blacklist from "pages/Settings/BlackList";
import BlockedDomains from "pages/Settings/BlockedDomains";
import BlockedRoles from "pages/Settings/BlockedRoles";
import AllowedTLDs from "pages/Settings/AllowedTLDs";
// import Users from "pages/Settings/Users";
import User from "pages/Settings/User";
import AuditLog from "pages/Tables/AuditLog";
import BlockedRole from "pages/Settings/BlockedRole";
import BlackListItem from "pages/Settings/BlackListItem";
import BlockedDomain from "pages/Settings/BlockedDomain";
import AllowedTLD from "pages/Settings/AllowedTLD";
import Login from "pages/Login";
import Signup from "pages/Register";
import RawItem from "pages/Tables/RawItem";
import Breeds from "pages/Tables/Breeds";
import Breed from "pages/Tables/Breed";
import Users from "pages/Settings/Users";
import ProtectedRoutes from "hoc/ProtectedRoutes";

const TableRoutes = {
  path: "/",
  element: <TablesLayout />,
  children: [
    {
      path: "/lists",
      element: <Lists />,
    },
    {
      path: "/scrubs",
      element: <Scrubs />,
    },
    {
      path: "/scrubs/vendors",
      element: <ScrubVendors />,
    },
    {
      path: "/jobs",
      element: <Jobs />,
    },
    {
      path: "/settings/blacklist",
      element: <Blacklist />,
    },
    {
      path: "/settings/blocked-domains",
      element: <BlockedDomains />,
    },
    {
      path: "/settings/blocked-roles",
      element: <BlockedRoles />,
    },
    {
      path: "/profiles",
      element: <Profiles />,
    },
    {
      path: "/tables/audit-log",
      element: <AuditLog />,
    },
    {
      path: "/tables/raw",
      element: <Raw />,
    },
    {
      path: "/settings/users",
      element: (
        <ProtectedRoutes>
          <Users />
        </ProtectedRoutes>
      ),
    },
    {
      path: "/settings/allowed-tlds",
      element: <AllowedTLDs />,
    },
    {
      path: "/tables/breeds",
      element: <Breeds />,
    },
  ],
};
const DashboardRoutes = {
  path: "/",
  element: <DashboardLayout />,
  children: [
    {
      path: "/",
      element: <Dashboard />,
    },

    {
      path: "/lists/:listId",
      element: <List />,
    },

    {
      path: "/scrubs/vendors/:id",
      element: <ScrubVendor />,
    },

    {
      path: "/settings/blacklist/:id",
      element: <BlackListItem />,
    },

    {
      path: "/settings/blocked-domains/:id",
      element: <BlockedDomain />,
    },

    {
      path: "/settings/blocked-roles/:id",
      element: <BlockedRole />,
    },

    {
      path: "/settings/allowed-tlds/:id",
      element: <AllowedTLD />,
    },

    {
      path: "/settings/users/:id",
      element: <User />,
    },

    {
      path: "/tables/raw/:id",
      element: <RawItem />,
    },

    {
      path: "/jobs/:id",
      element: <Job />,
    },

    {
      path: "/profiles/:id",
      element: <Profile />,
    },
    {
      path: "tables/breeds/:id",
      element: <Breed />,
    },
  ],
};

const AuthRoutes = {
  path: "/",
  children: [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Signup />,
    },
  ],
};

export default function ThemeRoutes() {
  return useRoutes([DashboardRoutes, TableRoutes, AuthRoutes]);
}
