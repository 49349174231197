import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import Sidebar from "components/ui/Sidebar";
import { drawerWidth } from "../../../constants";
import ToolBar from "components/ui/ToolBar";
import { Outlet, useNavigate } from "react-router-dom";
import AuthGuard from "lib/routeGuards/AuthGuard";
import { Button } from "@mui/material";
import { LogoutUsers } from "services/user";

export default function ResponsiveDrawer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AuthGuard>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            border: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={2}
          >
            <ToolBar handleDrawerToggle={handleDrawerToggle} />
            <Button
              variant="outlined"
              onClick={() => {
                LogoutUsers();
                navigate("/login");
              }}
              className="btnLogin"
            >
              Logout
            </Button>
          </Box>
        </AppBar>
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: { sm: 3, lg: 4 },
            pt: { sm: 2, lg: 2 },
            width: {
              sm: `calc(100% - ${drawerWidth}px)`,
              ...theme.mainContent,
            },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </AuthGuard>
  );
}
