import { useQuery } from '@tanstack/react-query';
import axiosService from 'lib/axios';
import { List } from 'types';

const route = 'lists';

// Deprecated
async function getLists(): Promise<List[]> {
    const { data } = await axiosService.get(route);
    return data;
}

// Deprecated
export function useGetLists() {
    return useQuery(['lists'], getLists);
}


export async function createNewList(list: Partial<List>) {
    const { data } = await axiosService.post(route, list);
    return data;
}



export async function updateList(list: Partial<List>) {
    const { data } = await axiosService.put(`${route}/${list.id}`, list);
    return data;
}

export async function uploadListToRaw(listId: string, data: FormData) {
    const { data: res } = await axiosService.post(`raw/upload/${listId}`, data);
    return res;
}

export async function makeProfileRecords(listId: string, uploadId?: string) {
    const { data } = await axiosService.post(`raw/addToProfile/${listId}?uploadId=${uploadId}`);
    return data;
}


export async function reverseImportMutation({ listId, uploadId }: { listId: string, uploadId: string }) {
    const { data } = await axiosService.delete(`raw/reverseImport/${listId}/${uploadId}`);
    return data;
}


async function getMailwizzLists(): Promise<{
    records: any[];
    count: string | number
}> {
    const { data } = await axiosService.get(`${route}/mailwizz`);
    return data;
}

export function useGetMailwizzLists() {
    return useQuery(['mailwizzLists'], getMailwizzLists, {
        retry: false
    });
}